import { action, autorun, computed, decorate, extendObservable, reaction } from "mobx";
import { RouteConstants } from "../../constants/RouteConstants";
import { CommonConstants } from "../../constants/CommonConstants";
import _ from "lodash";
import { AlertConstants } from "../../constants/AlertConstants";

class EventDetailsStore {
  constructor(authStore, commonStore, compTixApi, routerStore, accessGridStore, inventoryGridStore, alertStore) {
    this.authStore = authStore;
    this.commonStore = commonStore;
    this.compTixApi = compTixApi;
    this.routerStore = routerStore;
    this.accessGridStore = accessGridStore;
    this.inventoryGridStore = inventoryGridStore;
    this.alertStore = alertStore;

    this.defaults = {
      awayTeam: null,
      departments: [],
      event: null,
      inventoryOverrides: new Map(),
      gamePk: -1,
      homeTeam: null,
      orgs: [],
      overrides: [],
      sections: [],
      selectedDepartment: {},
      selectedEventAccessOverride: null,
      selectedEventBulkAccessOverride: null,
      selectedOrg: { label: "", value: "" },
      showEventAccessOverrideModal: false,
      showEventBulkAccessOverrideModal: false,
      selectedBulkOverrideDepartments: [],
      showEventAccessDeleteModal: false,
      showSectionDeleteModal: false,
      sectionToDelete: null,
      showEditSectionOverrideModal: false,
      sectionToEdit: null,
      showDeleteMessageModal: false,
      showUnlockGameModal: false,
      showLockGameModal: false,
      messageToDelete: -1,
      showAddMessageModal: false,
      eventMessages: [],
      departmentOptions: [],
      currentEditingEventMessage: { message: "", departments: [] },
      isSaving: false,
      tmEventIds: {}
    };

    extendObservable(this, {
      awayTeam: this.defaults["awayTeam"],
      departments: this.defaults["departments"],
      event: this.defaults["event"],
      gamePk: this.defaults["gamePk"],
      homeTeam: this.defaults["homeTeam"],
      orgs: this.defaults["orgs"],
      overrides: this.defaults["overrides"],
      tmEventIds: this.defaults["tmEventIds"],
      showEventAccessOverrideModal: this.defaults["showEventAccessOverrideModal"],
      showEventBulkAccessOverrideModal: this.defaults["showEventBulkAccessOverrideModal"],
      selectedBulkOverrideDepartments: this.defaults["selectedBulkOverrideDepartments"],
      showEventAccessDeleteModal: this.defaults["showEventAccessOverrideModal"],
      selectedDepartment: this.defaults["selectedDepartment"],
      selectedEventAccessOverride: this.defaults["selectedEventAccessOverride"],
      selectedEventBulkAccessOverride: this.defaults["selectedEventBulkAccessOverride"],
      selectedOrg: this.defaults["selectedOrg"],
      sections: this.defaults["sections"],
      inventoryOverrides: this.defaults["inventoryOverrides"],
      showSectionDeleteModal: this.defaults["showSectionDeleteModal"],
      sectionToDelete: this.defaults["sectionToDelete"],
      showEditSectionOverrideModal: false,
      sectionToEdit: null,
      showDeleteMessageModal: this.defaults["showDeleteMessageModal"],
      showUnlockGameModal: this.defaults["showUnlockGameModal"],
      showLockGameModal: this.defaults["showLockGameModal"],
      messageToDelete: this.defaults["messageToDelete"],
      showAddMessageModal: this.defaults["showAddMessageModal"],
      eventMessages: this.defaults["eventMessages"],
      eventMessageDepartments: this.defaults["eventMessageDepartments"],
      departmentOptions: this.defaults["departmentOptions"],
      currentEditingEventMessage: this.defaults["currentEditingEventMessage"],
      isSaving: this.defaults["isSaving"],
      setAwayTeam: action(value => {
        this.awayTeam = value;
      }),
      setDepartments: action(value => {
        this.departments = value;
      }),
      setEvent: action(value => {
        this.event = value;
      }),
      setGamePk: action(value => {
        this.gamePk = value;
      }),
      setHomeTeam: action(value => {
        this.homeTeam = value;
      }),
      setOrgs: action(orgs => {
        this.orgs = orgs;
      }),
      setOverrides: action(values => {
        this.overrides = values;
      }),
      setSelectedDepartment: action(value => {
        this.selectedDepartment = value;
      }),
      setSelectedOrg: action(org => {
        this.selectedOrg = org;
      }),
      setSections: action(value => {
        this.sections = value;
      }),
      setSelectedEventAccessOverride: action(value => {
        this.selectedEventAccessOverride = value;
      }),
      setSelectedEventBulkAccessOverride: action(value => {
        this.selectedEventBulkAccessOverride = value;
      }),
      setTmEventIds: action(value => {
        this.tmEventIds = value;
      }),
      setShowEventAccessDeleteModal: action(value => {
        this.showEventAccessDeleteModal = value;
        if (!value) {
          this.selectedDepartment = this.defaults["selectedDepartment"];
          this.selectedEventAccessOverride = this.defaults["selectedEventAccessOverride"];
        }
      }),
      setShowEventAccessOverrideModal: action(value => {
        this.showEventAccessOverrideModal = value;
        if (!value) {
          this.selectedDepartment = this.defaults["selectedDepartment"];
          this.selectedEventAccessOverride = this.defaults["selectedEventAccessOverride"];
          this.getOverrides();
        }
      }),
      setShowEventBulkAccessOverrideModal: action(value => {
        this.showEventBulkAccessOverrideModal = value;
        this.selectedEventBulkAccessOverride = !value
          ? this.defaults["selectedEventBulkAccessOverride"]
          : this.newEventOverride();
        this.addBulkOverrideDepartment(null, { action: "clear" });
      }),
      setSelectedBulkOverrideDepartments: action(value => {
        this.selectedBulkOverrideDepartments = value;
      }),
      addBulkOverrideDepartment: action((value, event) => {
        if (event) {
          switch (event.action) {
            case "select-option":
              this.selectedBulkOverrideDepartments.push(event.option);
              break;
            case "remove-value":
              let dept = event.removedValue;
              this.selectedBulkOverrideDepartments.splice(
                this.selectedBulkOverrideDepartments.indexOf(
                  this.selectedBulkOverrideDepartments.find(d => d.value === dept.value)
                ),
                1
              );
              break;
            case "clear":
              this.setSelectedBulkOverrideDepartments(this.defaults["selectedBulkOverrideDepartments"]);
              break;
            default:
              break;
          }
        }
      }),
      addAllBulkDepartments: action(() => {
        this.setSelectedBulkOverrideDepartments(
          this.departments.map(d => {
            return { value: d.departmentId, label: d.departmentType.departmentName };
          })
        );
      }),
      setShowDeleteSectionModal: action(value => {
        this.showSectionDeleteModal = value;
      }),
      setMessageToDelete: action(value => {
        this.messageToDelete = value;
      }),
      setSectionToDelete: action(value => {
        this.sectionToDelete = value;
      }),
      setShowEditSectionModal: action(value => {
        this.showEditSectionOverrideModal = value;
      }),
      setSectionToEdit: action(value => {
        this.sectionToEdit = value;
      }),
      updateSectionToEditPrice: action(value => {
        this.sectionToEdit.override.price = value;
      }),
      updateSectionToEditQuantity: action(value => {
        this.sectionToEdit.override.quantity = value ? Math.trunc(value) : null;
      }),
      setInventoryOverrides: action(values => {
        this.inventoryOverrides = new Map(); // Needed for mobx to trigger re-renders
        values.forEach(value => this.inventoryOverrides.set(value.sectionId, value));
      }),
      parseUrl: action(() => {
        let params = this.routerStore.getPathParams(RouteConstants.EVENT_DETAILS);
        this.setGamePk(params.gamePk);
      }),
      deleteEventAccessOverride: action(() => {
        this.deleteEventOverride(this.selectedEventAccessOverride.eventOverrideId);
      }),
      setShowDeleteMessageModal: action(show => {
        this.showDeleteMessageModal = show;
      }),
      setShowUnlockGameModal: action(show => {
        this.showUnlockGameModal = show;
      }),
      setShowLockGameModal: action(show => {
        this.showLockGameModal = show;
      }),
      setShowAddMessageModal: action(show => {
        this.currentEditingEventMessage = { message: "", departments: [], gamePk: this.gamePk };
        this.showAddMessageModal = show;
      }),
      setShowEditMessageModal: action((show, message) => {
        if (show) {
          this.currentEditingEventMessage = message;
        } else {
          this.currentEditingEventMessage = this.defaults["currentEditingEventMessage"];
        }
        this.showAddMessageModal = show;
      }),
      setCurrentMessage: action(message => {
        this.currentEditingEventMessage.message = message.target.value;
      }),
      setCurrentDepartments: action(departments => {
        this.currentEditingEventMessage.departments = departments;
      }),
      setEventMessages: action(messages => {
        this.eventMessages = messages;
      }),
      setEventMessageDepartments: action(departments => {
        this.currentEditingEventMessage.departments = departments;
      }),
      saveEventAccessOverride: action(() => {
        this.isSaving = true;
        if (
          this.selectedEventAccessOverride.eventOverrideId &&
          !this.selectedEventAccessOverride.useAccess &&
          !this.selectedEventAccessOverride.useCutoff &&
          !this.selectedEventAccessOverride.useAllotment
        ) {
          this.deleteEventOverride(this.selectedEventAccessOverride.eventOverrideId);
        } else if (this.selectedEventAccessOverride.eventOverrideId) {
          this.updateEventOverride(this.selectedEventAccessOverride);
        } else {
          this.saveEventOverride(this.selectedEventAccessOverride);
        }
      }),
      saveEventBulkAccessOverride: action(() => {
        if (this.selectedEventBulkAccessOverride) {
          this.saveEventBulkOverride(this.selectedEventBulkAccessOverride);
        }
      }),
      resetStore: action(() => {
        this.homeTeam = this.defaults["homeTeam"];
        this.awayTeam = this.defaults["awayTeam"];
        this.orgs = this.defaults["orgs"];
        this.overrides = this.defaults["overrides"];
        this.departments = this.defaults["departments"];
        this.sections = this.defaults["sections"];
        this.event = this.defaults["event"];
        this.showAddMessageModal = this.defaults["showAddMessageModal"];
        this.showDeleteMessageModal = this.defaults["showDeleteMessageModal"];
        this.eventMessages = this.defaults["eventMessages"];
        this.currentMessage = this.defaults["currentMessage"];
        this.currentMessageDepartments = this.defaults["currentMessageDepartments"];
      })
    });

    autorun(() => {
      if (this.routerStore.isEventDetailsTab) {
        this.parseUrl();
        this.getEvent();
        this.getOverrides();
      } else {
        this.resetStore();
      }
    });

    autorun(() => {
      if (this.homeTeam && this.awayTeam) {
        this.createOrgDropdown();
        this.compTixApi.getEventMapping(this.homeTeam.statsId, this.gamePk).then(data => this.setTmEventIds(data.data));
      }
    });

    autorun(() => {
      if (this.selectedOrg && this.selectedOrg.value && this.selectedOrg.value) {
        this.compTixApi.getDepartmentsByOrg(this.selectedOrg.value).then(data => {
          this.setDepartments(data);
          this.getEventMessages();
        });
      }
    });

    reaction(
      () => this.event,
      () => {
        if (this.event) {
          if (this.event.venueSeasonId) {
            this.compTixApi.getSections(this.event.venueSeasonId).then(values => {
              this.setSections(values);
            });
          } else {
            this.alertStore.addAlert({
              type: AlertConstants.TYPES.DANGER,
              text: "No Venue mapping for this year and season type exists"
            });
          }
          this.compTixApi.getInventoryOverride(this.event.gamePk).then(values => {
            this.setInventoryOverrides(values);
          });
        }
      }
    );
  }

  cancel() {
    this.resetStore();
    this.routerStore.history.push("/admin/events");
  }

  createOrgDropdown() {
    let home = { label: this.homeTeam.orgCode, value: this.homeTeam.orgId, statsId: this.homeTeam.statsId };
    let away = { label: this.awayTeam.orgCode, value: this.awayTeam.orgId, statsId: this.awayTeam.statsId };
    let ump = { label: CommonConstants.KEYS.UMP_ORGCODE, value: CommonConstants.KEYS.UMP_ID, statsId: null };

    // Global user
    if (this.authStore.isGlobal) {
      this.setOrgs([home, away, ump].filter(team => team.label));
      switch (this.commonStore.currentOrg.orgId) {
        case home.value:
          this.setSelectedOrg(home);
          break;
        case away.value:
          this.setSelectedOrg(away);
          break;
        case ump.value:
          this.setSelectedOrg(ump);
          break;
        default:
          this.setSelectedOrg(home);
          break;
      }
    }
    // box office Admin
    else if (this.authStore.isBoxOfficeAdmin && this.homeTeam.orgId === this.authStore.userData.org.orgId) {
      this.setOrgs([home, away, ump].filter(team => team.label));
      this.setSelectedOrg(home);
    } else if (this.authStore.isBoxOfficeAdmin && this.awayTeam.orgId === this.authStore.userData.org.orgId) {
      this.setOrgs([away].filter(team => team.label));
      this.setSelectedOrg(away);
    }
    // Admin - Home Team
    else if (this.authStore.isAdmin && this.homeTeam.orgId === this.authStore.userData.org.orgId) {
      this.setOrgs([home, away, ump].filter(team => team.label));
      this.setSelectedOrg(home);
    }
    // Admin - Away Team
    else if (this.authStore.isAdmin && this.awayTeam.orgId === this.authStore.userData.org.orgId) {
      this.setOrgs([away].filter(team => team.label));
      this.setSelectedOrg(away);
    }
    // Admin - Umpire
    else if (this.authStore.isAdmin) {
      this.setOrgs([ump]);
      this.setSelectedOrg(ump);
    }
  }

  getEvent() {
    this.compTixApi.getEventInformation(this.gamePk).then(data => {
      this.setEvent(data);
      this.getTeams(data);
      this.getEventMessages();
    });
  }

  getEventMessages() {
    if (!this.selectedOrg || !this.selectedOrg.value) {
      return;
    }
    this.compTixApi.getEventMessages(this.gamePk, this.selectedOrg.value).then(data => {
      let enriched = data.map(message => {
        const departments = message.departments.map(department => {
          return {
            label: department.departmentType.departmentName,
            value: department.departmentId,
            key: department.departmentId,
            department: department
          };
        });
        return {
          id: message.id,
          eventId: message.gamePk,
          departments: message.departments,
          departmentNames:
            message.departments.length === 11
              ? "All"
              : _.sortBy(message.departments, "departmentId")
                  .map(department => department.departmentType.departmentName)
                  .join(", "),
          message: message.message,
          editAndDelete: {
            onDeleteClick: () => {
              this.setShowDeleteMessageModal(true);
              this.setMessageToDelete(message.id);
            },
            onEditClick: () => {
              this.setShowAddMessageModal(true);
              this.setShowEditMessageModal(true, {
                id: message.id,
                message: message.message,
                eventId: message.gamePk,
                departments: departments,
                gamePk: message.gamePk
              });
            },
            eventId: message.eventId
          }
        };
      });
      this.setEventMessages(_.sortBy(enriched, "id"));
    });
  }

  getOverrides = () => {
    this.compTixApi.getEventOverridesByGamePk(this.gamePk).then(data => {
      this.setOverrides(data);
    });
  };

  getTeams(data) {
    this.compTixApi
      .getOrgByStatsId(data.teams.home.team.id)
      .then(results => {
        this.setHomeTeam(results);
      })
      .catch(unused => {
        console.warn("No team with stats id " + data.teams.home.team.id);
        this.setHomeTeam({});
      });
    if (data.teams.away.team) {
      this.compTixApi
        .getOrgByStatsId(data.teams.away.team.id)
        .then(results => {
          this.setAwayTeam(results);
        })
        .catch(unused => {
          console.warn("No team with stats id " + data.teams.away.team.id);
          this.setAwayTeam({});
        });
    } else {
      this.setAwayTeam({});
    }
  }

  deleteSectionOverride = sectionOverrideId => {
    this.isSaving = true;
    this.compTixApi
      .deleteInventoryOverride(sectionOverrideId)
      .then(unused => {
        this.setSectionToDelete(null);
        this.setShowDeleteSectionModal(false);
        this.setSectionToEdit(null);
        this.setShowEditSectionModal(false);
        this.compTixApi
          .getInventoryOverride(this.event.gamePk)
          .then(values => {
            this.setInventoryOverrides(values);
            this.isSaving = false;
          })
          .catch(error => {
            this.isSaving = false;
          });
      })
      .catch(error => {
        this.isSaving = false;
      });
  };

  editSectionOverride = sectionToEdit => {
    this.isSaving = true;
    if (sectionToEdit.override.quantity != null || sectionToEdit.override.price != null) {
      let updatedOverride = Object.assign({}, sectionToEdit.originalOverride);

      if (sectionToEdit.override.quantity == null) {
        updatedOverride.quantity = null;
      } else if (sectionToEdit.override.price >= 0) {
        updatedOverride.quantity = sectionToEdit.override.quantity;
      } else {
        updatedOverride.quantity = null;
      }

      if (sectionToEdit.override.price || sectionToEdit.override.price === 0) {
        updatedOverride.price = parseFloat(sectionToEdit.override.price).toFixed(2);
      } else {
        updatedOverride.price = null;
      }

      this.compTixApi
        .updateInventoryOverride(sectionToEdit.originalOverride.inventoryOverrideId, updatedOverride)
        .then(res => {
          this.setSectionToEdit(null);
          this.setShowEditSectionModal(false);
          this.compTixApi
            .getInventoryOverride(this.event.gamePk)
            .then(values => {
              this.setInventoryOverrides(values);
              this.isSaving = false;
            })
            .catch(error => {
              this.saving = false;
            });
        });
    } else {
      this.deleteSectionOverride(sectionToEdit.originalOverride.inventoryOverrideId)
        .then(() => {
          this.saving = false;
        })
        .catch(error => {
          this.saving = false;
        });
    }
  };

  saveEventBulkOverride = eventBulkOverride => {
    if (eventBulkOverride) {
      this.compTixApi
        .saveEventBulkOverride(
          eventBulkOverride,
          this.bulkOverrideDepartments
            .map(d => {
              return d.value;
            })
            .join()
        )
        .then(data => {
          this.setShowEventBulkAccessOverrideModal(false);
          this.getOverrides();
          this.addBulkOverrideDepartment(null, { action: "clear" });
        });
    }
  };

  saveEventOverride = eventOverride => {
    this.compTixApi.saveEventOverride(eventOverride).then(data => {
      this.setShowEventAccessOverrideModal(false);
      this.getOverrides();
      this.isSaving = false;
    });
  };

  updateEventOverride = eventOveride => {
    this.compTixApi.updateEventOverride(eventOveride).then(data => {
      this.setShowEventAccessOverrideModal(false);
      this.getOverrides();
      this.isSaving = false;
    });
  };

  deleteEventOverride = eventOverrideId => {
    this.compTixApi.deleteEventOverride(eventOverrideId).then(data => {
      this.setShowEventAccessDeleteModal(false);
      this.setShowEventAccessOverrideModal(false);
      this.getOverrides();
      this.isSaving = false;
    });
  };

  saveEventMessage = () => {
    this.isSaving = true;
    const currentEditingEventMessage = this.currentEditingEventMessage;
    if (currentEditingEventMessage) {
      const message = {
        gamePk: currentEditingEventMessage.gamePk,
        message: currentEditingEventMessage.message,
        id: currentEditingEventMessage.id,
        departments: currentEditingEventMessage.departments.map(department => department.department)
      };
      if (message.id) {
        this.compTixApi
          .updateEventMessage(message)
          .then(data => {
            this.setShowAddMessageModal(false);
            this.getEventMessages();
            this.isSaving = false;
          })
          .catch(error => {
            this.isSaving = false;
          });
      } else {
        this.compTixApi
          .createEventMessage(message)
          .then(data => {
            this.setShowAddMessageModal(false);
            this.getEventMessages();
            this.isSaving = false;
          })
          .catch(error => {
            this.isSaving = false;
          });
      }
    }
  };

  deleteEventMessage = gamePk => {
    this.compTixApi.deleteEventMessage(gamePk).then(data => {
      this.getEventMessages();
      this.setShowDeleteMessageModal(false);
    });
  };

  unlockGame = (gamePk, teamId) => {
    this.compTixApi.unlockGame(gamePk, teamId).then(data => {
      this.getEvent();
      this.setShowUnlockGameModal(false);
    });
  };

  lockGame = (gamePk, teamId) => {
    this.compTixApi.lockGame(gamePk, teamId).then(data => {
      this.getEvent();
      this.setShowLockGameModal(false);
    });
  };

  updateEventMessage = data => {};

  createSectionOverride(sectionToEdit) {
    this.isSaving = true;
    if (sectionToEdit.override.quantity != null || sectionToEdit.override.price != null) {
      let overrideToCreate = sectionToEdit.originalOverride;

      if (sectionToEdit.override.quantity != null && sectionToEdit.override.quantity >= 0) {
        overrideToCreate.quantity = sectionToEdit.override.quantity;
      }

      if (sectionToEdit.override.price || sectionToEdit.override.price === 0) {
        overrideToCreate.price = parseFloat(sectionToEdit.override.price).toFixed(2);
      }

      this.compTixApi.createInventoryOverride(overrideToCreate).then(res => {
        this.setSectionToEdit(null);
        this.setShowEditSectionModal(false);
        this.compTixApi
          .getInventoryOverride(this.event.gamePk)
          .then(values => {
            this.setInventoryOverrides(values);
            this.isSaving = false;
          })
          .catch(error => {
            this.saving = false;
          });
      });
    } else {
      this.setSectionToEdit(null);
      this.setShowEditSectionModal(false);
    }
  }

  get currentDepartments() {
    let currentDepartments = this.departments
      .map((d, index) => {
        let access = d.departmentAccess.find(da => da.season.seasonId === this.event.seasonId);
        let allotment = d.departmentAllotment.find(da => da.season.seasonId === this.event.seasonId);
        if (access) {
          //format time based on event
          let accessValue = access.availableUseDate ? access.availableDate : access.availableMinutesBefore;
          let cutoffValue = access.cutoffUseDate ? access.cutoffDate : access.cutoffMinutesBefore;
          let override = this.overrides.find(o => o.departmentId === d.departmentId);
          let overrideAccessDetails = null;
          let overrideCutoffDetails = null;
          let overrideHomeFamily = null;
          let overrideHomeFriends = null;
          let overrideAwayFamily = null;
          let overrideAwayFriends = null;

          let overrideAccessValue = null;
          let overrideCutoffValue = null;

          if (override) {
            override.timeZone = this.timeZone;
            overrideAccessValue = override.availableUseDate ? override.availableDate : override.availableMinutesBefore;
            overrideCutoffValue = override.cutoffUseDate ? override.cutoffDate : override.cutoffMinutesBefore;

            overrideAccessDetails = {
              isDate: override.availableUseDate,
              timeZone: this.timeZone,
              value: overrideAccessValue,
              showOverride: override.useAccess
            };

            overrideCutoffDetails = {
              isDate: override.cutoffUseDate,
              timeZone: this.timeZone,
              value: overrideCutoffValue,
              showOverride: override.useCutoff
            };

            if (override.useAllotment) {
              overrideHomeFamily = {
                isDate: false,
                value: override.homeFamily,
                showOverride: override.homeFamily != null
              };
              overrideHomeFriends = {
                isDate: false,
                value: override.homeFriends,
                showOverride: override.homeFriends != null
              };
              overrideAwayFamily = {
                isDate: false,
                value: override.awayFamily,
                showOverride: override.awayFamily != null
              };
              overrideAwayFriends = {
                isDate: false,
                value: override.awayFriends,
                showOverride: override.awayFriends != null
              };
            }
          }

          return {
            overrideType: "department",
            editAndDelete: {
              index: index,
              onAdd: () => {
                this.setSelectedDepartment(d);
                this.setSelectedEventAccessOverride(this.newEventOverride());
                this.setShowEventAccessOverrideModal(true);
              },
              onEdit: () => {
                this.setSelectedDepartment(d);
                this.setSelectedEventAccessOverride(override);
                this.setShowEventAccessOverrideModal(true);
              },
              onDelete: () => {
                this.setSelectedDepartment(d);
                this.setSelectedEventAccessOverride(override);
                this.setShowEventAccessDeleteModal(true);
              },
              hasOverrides: !!override
            },
            departmentId: d.departmentId,
            access: {
              isDate: access.availableUseDate,
              timeZone: this.timeZone,
              value: accessValue,
              override: overrideAccessDetails
            },
            cutoff: {
              isDate: access.cutoffUseDate,
              timeZone: this.timeZone,
              value: cutoffValue,
              override: overrideCutoffDetails
            },
            departmentName: d.departmentType.departmentName,
            homeFamily: { value: allotment.homeFamilyAllotment, override: overrideHomeFamily },
            homeFriends: { value: allotment.homeFriendsAllotment, override: overrideHomeFriends },
            awayFamily: { value: allotment.awayFamilyAllotment, override: overrideAwayFamily },
            awayFriends: { value: allotment.awayFriendsAllotment, override: overrideAwayFriends }
          };
        } else {
          return null;
        }
      })
      .filter(d => d != null);
    return this.accessGridStore.sort(currentDepartments);
  }

  get orgDropdown() {
    return this.orgs.toJSON();
  }

  get isHome() {
    return this.homeTeam?.orgId === this.authStore.userData.org.orgId;
  }

  get sectionsAndInventoryOverrides() {
    return this.inventoryGridStore.sort(
      this.sections
        .filter(section => {
          if (this.selectedOrgIsHome) {
            return section.homeAwayType.homeAwayTypeId === 1;
          } else if (this.selectedOrgIsAway) {
            return section.homeAwayType.homeAwayTypeId === 2;
          } else {
            return section.homeAwayType.homeAwayTypeId === 3;
          }
        })
        .map(section => {
          const override = this.inventoryOverrides.get(section.sectionId)
            ? this.inventoryOverrides.get(section.sectionId)
            : {};
          const hasOverrides = override.quantity != null || override.price != null;
          return {
            overrideType: "section",
            editAndDelete: {
              onAdd: () => {
                this.setSectionToEdit({
                  sectionNumber: section.sectionNumber,
                  override: {
                    price: "",
                    quantity: ""
                  },
                  originalOverride: this.newInventoryOverride(section)
                });
                this.setShowEditSectionModal(true);
              },
              onEdit: () => {
                this.setSectionToEdit({
                  sectionNumber: section.sectionNumber,
                  override: {
                    price: override.price,
                    quantity: override.quantity
                  },
                  originalOverride: override
                });
                this.setShowEditSectionModal(true);
              },
              onDelete: () => {
                this.setShowDeleteSectionModal(true);
                this.setSectionToDelete({ section: section.sectionNumber, overrideId: override.inventoryOverrideId });
              },
              hasOverrides: hasOverrides
            },
            editOnly: {
              onAdd: () => {
                this.setSectionToEdit({
                  sectionNumber: section.sectionNumber,
                  override: {
                    price: "",
                    quantity: ""
                  },
                  originalOverride: this.newInventoryOverride(section)
                });
                this.setShowEditSectionModal(true);
              },
              onEdit: () => {
                this.setSectionToEdit({
                  sectionNumber: section.sectionNumber,
                  override: {
                    price: override.price,
                    quantity: override.quantity
                  },
                  originalOverride: override
                });
                this.setShowEditSectionModal(true);
              },
              hasOverrides: hasOverrides
            },
            section: section.sectionNumber,
            inventory: { value: section.inventoryQuantity, override: override.quantity },
            price: { value: section.sectionPrice, override: override.price, isPrice: true }
          };
        })
    );
  }

  get selectedOrgIsHome() {
    if (this.event && this.selectedOrg) {
      return this.event.teams.home.team.id === this.selectedOrg.statsId;
    }
    return true;
  }

  get selectedOrgIsAway() {
    if (this.event && this.selectedOrg) {
      return this.event.teams.away.team && this.event.teams.away.team.id === this.selectedOrg.statsId;
    }
    return true;
  }

  get bulkOverrideDepartments() {
    return this.selectedBulkOverrideDepartments.map(d => {
      return { value: d.value, label: d.label };
    });
  }

  newEventOverride() {
    const todayAtNoon = new Date();
    todayAtNoon.setHours(12);
    todayAtNoon.setMinutes(0);
    return {
      timeZone: this.timeZone,
      gamePk: this.gamePk,
      departmentId: this.selectedDepartment.departmentId,
      availableDate: new Date(todayAtNoon),
      availableMinutesBefore: 0,
      availableUseDate: true,
      useAccess: false,
      cutoffDate: new Date(todayAtNoon),
      cutoffMinutesBefore: 0,
      cutoffUseDate: true,
      useCutoff: false,
      isDeleted: false,
      useAllotment: false,
      homeFamily: null,
      homeFriends: null,
      awayFamily: null,
      awayFriends: null
    };
  }

  newInventoryOverride(section) {
    return {
      gamePk: parseInt(this.gamePk),
      sectionId: parseInt(section.sectionId),
      homeAwayType: section.homeAwayType,
      price: null,
      quantity: null
    };
  }

  get isOverridesValid() {
    return (
      this.sectionToEdit != null &&
      (this.sectionToEdit.override.price == null || this.sectionToEdit.override.price >= 0) &&
      (this.sectionToEdit.override.quantity == null || this.sectionToEdit.override.quantity >= 0)
    );
  }

  get isValidEventAccessOverride() {
    return (
      this.selectedEventAccessOverride &&
      (this.selectedEventAccessOverride.eventOverrideId ||
        this.selectedEventAccessOverride.useAccess ||
        this.selectedEventAccessOverride.useCutoff ||
        this.selectedEventAccessOverride.useAllotment)
    );
  }

  get departmentDropdown() {
    return this.departments
      ? this.departments.map(d => ({
          label: d.departmentType.departmentName,
          value: d.departmentId,
          key: d.departmentId,
          department: d
        }))
      : [];
  }

  get timeZone() {
    return this.event && this.event.venue && this.event.venue.timeZone && this.event.venue.timeZone.id
      ? this.event.venue.timeZone.id
      : this.commonStore.currentOrg.timeZone;
  }

  get isLocked() {
    return this.event && this.event.locked;
  }
}

decorate(EventDetailsStore, {
  currentDepartments: computed,
  orgDropdown: computed,
  sectionsAndInventoryOverrides: computed,
  selectedOrgIsHome: computed,
  selectedOrgIsAway: computed,
  isOverridesValid: computed,
  isValidEventAccessOverride: computed,
  bulkOverrideDepartments: computed,
  departmentDropdown: computed,
  timeZone: computed,
  isLocked: computed,
  isHome: computed
});

export default EventDetailsStore;
