import axios from "axios";

const SERVICES_URL = process.env.REACT_APP_SERVICES_URL;

class CompTixApi {
  getHistory = (orgCode, reportType, startDate, endDate) => {
    const url =
      SERVICES_URL +
      "/report/history" +
      "?orgCode=" +
      orgCode +
      "&reportType=" +
      reportType +
      "&startDate=" +
      startDate +
      "&endDate=" +
      endDate;
    return axios.get(url).then(response => response.data.entity);
  };

  getHistoryTypes = () => {
    const url = SERVICES_URL + "/report/history/report/type";
    return axios.get(url).then(response => response.data.entity);
  };

  getRoles = () => {
    const url = SERVICES_URL + "/roles";
    return axios.get(url).then(response => response.data.entity);
  };

  getDepartment = departmentId => {
    const url = SERVICES_URL + "/departments/" + departmentId;
    return axios.get(url).then(response => response.data.entity);
  };

  saveDepartment = department => {
    const url = SERVICES_URL + "/department";
    return axios.put(url, department).then(response => response.data.entity);
  };

  getDepartmentsByOrg = (orgId, includeAccess = true) => {
    const url = SERVICES_URL + "/orgs/" + orgId + "/departments?includeAccess=" + includeAccess;
    return axios.get(url).then(response => response.data.entity);
  };

  getDepartmentTypes = () => {
    const url = SERVICES_URL + "/departments/types";
    return axios.get(url).then(response => response.data.entity);
  };

  getHomeAwayTypes = () => {
    const url = SERVICES_URL + "/homeAway/types";
    return axios.get(url).then(response => response.data.entity);
  };

  getCategoryTypes = () => {
    const url = SERVICES_URL + "/report/filter/category";
    return axios.get(url).then(response => response.data.entity);
  };

  getHouseholdTypes = () => {
    const url = SERVICES_URL + "/household/types";
    return axios.get(url).then(response => response.data.entity);
  };

  getUsersByOrg = (orgId, includeDeptAccess = true, includeUserAccess = true) => {
    const url =
      SERVICES_URL +
      "/orgs/" +
      orgId +
      "/users?includeDeptAccess=" +
      includeDeptAccess +
      "&includeUserAccess=" +
      includeUserAccess;
    return axios.get(url).then(response => response.data.entity);
  };

  getUserEventInfo = (gamePk, userId) => {
    const url = SERVICES_URL + "/users/" + userId + "/event/" + gamePk;
    return axios.get(url).then(response => response.data.entity);
  };

  resetPassword = (userId, password1, password2) => {
    const url = SERVICES_URL + "/users/reset/password";
    const body = {
      userId: userId,
      password: password1,
      confirmPassword: password2
    };
    return axios.put(url, body).then(response => response.data.entity);
  };

  bulkUpdateUserAllotments = (userIds, year, allotments) => {
    const url = SERVICES_URL + "/users/allotment/bulk";
    const body = {
      userIds,
      year,
      allotments
    };
    return axios.post(url, body).then(response => {
      if (response.data.alerts) {
        // this endpoint returns 207 Multi-Status
        // since errors are inside the payload
        // the top level alerts signifies to list out the errors for each override
        response.data.entity.forEach(o => {
          if (o.httpStatus === 500) {
            console.error(o.message);
          }
        });
      }
      return response.data.entity;
    });
  };

  getOrgs = () => {
    const url = SERVICES_URL + "/orgs";
    return axios.get(url).then(response => response.data.entity);
  };

  getOrgById = orgId => {
    const url = SERVICES_URL + "/orgs/" + orgId;
    return axios.get(url).then(response => response.data.entity);
  };

  getOrgSettings = orgId => {
    const url = SERVICES_URL + "/orgs/settings/" + orgId;
    return axios.get(url).then(response => response.data.entity);
  };

  getOrgByStatsId = orgId => {
    const url = SERVICES_URL + "/orgs/stats/" + orgId;
    return axios.get(url).then(response => response.data.entity);
  };

  getUserById = userId => {
    const url = SERVICES_URL + "/users/" + userId;
    return axios.get(url).then(response => response.data.entity);
  };

  getPersonInfoByStatsId = statsId => {
    const url = SERVICES_URL + "/users/stats/" + statsId;
    return axios.get(url).then(response => response.data.entity);
  };

  saveNewUser = user => {
    const url = SERVICES_URL + "/users";
    return axios.post(url, user).then(response => response.data.entity);
  };

  updateUser = user => {
    const url = SERVICES_URL + "/users";
    return axios.put(url, user).then(response => response.data.entity);
  };

  getVenueById = venueId => {
    const url = SERVICES_URL + "/venues/" + venueId;
    return axios.get(url).then(response => response.data.entity);
  };

  getVenues = orgId => {
    const url = SERVICES_URL + "/orgs/" + orgId + "/venues";
    return axios.get(url).then(response => response.data.entity);
  };

  getPickupLocations = venueSeasonId => {
    const url = SERVICES_URL + "/venues/" + venueSeasonId + "/locations";
    return axios.get(url).then(response => response.data.entity);
  };

  getPickupLocationsMulti = venueSeasonIds => {
    const url = SERVICES_URL + "/locations?vsIds=" + venueSeasonIds;
    return axios.get(url).then(response => response.data.entity);
  };

  savePickupLocations = (pickupLocations, venueSeasonId) => {
    const createdOrUpdated = pickupLocations.filter(pickupLocation => {
      return !(pickupLocation.pickupLocationId == null && pickupLocation.isDeleted);
    });
    const url = SERVICES_URL + "/venues/" + venueSeasonId + "/locations";
    return axios.put(url, createdOrUpdated).then(response => response.data.entity);
  };

  importVenue = (venueSeasonId, importedVenue) => {
    const url = SERVICES_URL + "/venues/" + venueSeasonId + "/import";
    return axios.put(url, importedVenue).then(response => response.data.entity);
  };

  // Front page
  getEvents = (orgId, startDate, endDate) => {
    const url = SERVICES_URL + "/events/" + orgId + "?startDate=" + startDate + "&endDate=" + endDate;
    return axios.get(url).then(response => response.data.entity);
  };

  getEventsForUmpire = (umpireComptixId, startDate, endDate) => {
    const url = SERVICES_URL + "/umpires/events/" + umpireComptixId + "?startDate=" + startDate + "&endDate=" + endDate;
    return axios.get(url).then(response => response.data.entity);
  };

  getUmpEventsByOrg = (umpOrgId, orgId, startDate, endDate) => {
    const url = `${SERVICES_URL}/umpires/events/${umpOrgId}/team/${orgId}?startDate=${startDate}&endDate=${endDate}`;
    return axios.get(url).then(response => response.data.entity);
  };

  getEventInformation = gamePk => {
    const url = SERVICES_URL + "/events/" + gamePk + "/info";
    return axios.get(url).then(response => response.data.entity);
  };

  getInformationForEvents = gamePks => {
    const url = SERVICES_URL + "/events/information/multi?gamePks=" + gamePks;
    return axios.get(url).then(response => response.data.entity);
  };

  getEventMessagesMulti = (gamePks, userId) => {
    const url = SERVICES_URL + "/eventMessage/multi/info/" + userId + "?gamePks=" + gamePks;
    return axios.get(url).then(response => response.data.entity);
  };

  getEventMessages = (gamePk, orgId) => {
    const url = SERVICES_URL + "/eventMessage/" + gamePk + "/org/" + orgId;
    return axios.get(url).then(response => response.data.entity);
  };

  getEventMessageForUser = (gamePk, userId) => {
    const url = SERVICES_URL + "/eventMessage/" + gamePk + "/user/" + userId;
    return axios.get(url).then(response => response.data.entity);
  };

  getEventMessagesForUser = (userId, gamePks) => {
    const url = SERVICES_URL + "/eventMessage/user/" + userId;
    return axios.post(url, { gamePks }).then(response => response.data.entity);
  };

  createEventMessage = message => {
    const url = SERVICES_URL + "/eventMessage/";
    return axios.post(url, message).then(response => response.data.entity);
  };

  updateEventMessage = message => {
    const url = SERVICES_URL + "/eventMessage/" + message.id;
    return axios.put(url, message).then(response => response.data.entity);
  };

  deleteEventMessage = gamePk => {
    const url = SERVICES_URL + "/eventMessage/" + gamePk;
    return axios.delete(url).then(response => response.entity);
  };

  unlockGame = (gamePk, teamId) => {
    const url = SERVICES_URL + "/lockedGames/unlock";
    return axios.post(url, { gamePk: gamePk, teamId: teamId }).then(response => response.entity);
  };

  lockGame = (gamePk, teamId) => {
    const url = SERVICES_URL + "/lockedGames/lock";
    return axios.post(url, { gamePk: gamePk, teamId: teamId }).then(response => response.entity);
  };

  getGamesByOrgAndSeasonAndVenue = (orgId, seasonId, venueId, startDate) => {
    let url = SERVICES_URL + "/events/season/" + seasonId + "/org/" + orgId + "/venue/" + venueId;
    if (startDate) {
      url += "?startDate=" + startDate;
    }
    return axios.get(url).then(response => response.data.entity);
  };

  getSectionsForOrgSeasonAndVenue = (orgId, seasonId, venueId) => {
    const url = SERVICES_URL + "/section/season/" + seasonId + "/org/" + orgId + "/venue/" + venueId;
    return axios.get(url).then(response => response.data.entity);
  };

  getSections = venueSeasonId => {
    const url = SERVICES_URL + "/venues/" + venueSeasonId + "/sections";
    return axios.get(url).then(response => response.data.entity);
  };

  getReservationsForUser = userId => {
    const url = SERVICES_URL + "/ticketRequest/user/" + userId + "/current";
    return axios.get(url).then(response => response.data.entity);
  };

  getPastReservationsForUser = (userId, orgId, start, end) => {
    const url =
      SERVICES_URL + "/ticketRequest/user/" + userId + "/past?orgId=" + orgId + "&start=" + start + "&end=" + end;
    return axios.get(url).then(response => response.data.entity);
  };

  getPastReservationsForOrg = (orgId, start, end) => {
    const url = SERVICES_URL + "/ticketRequest/org/" + orgId + "/past?start=" + start + "&end=" + end;
    return axios.get(url).then(response => response.data.entity);
  };

  getUsersForRequestDates = (orgId, startDate, endDate) => {
    const url = SERVICES_URL + `/ticketRequest/user/list/org/${orgId}/past?start=${startDate}&end=${endDate}`;
    return axios.get(url).then(response => response.data.entity);
  };

  getTicketReservationsForUserByDateRange = (userId, orgId, startDate, endDate) => {
    const url =
      SERVICES_URL +
      "/ticketRequest/user/" +
      userId +
      "/org/" +
      orgId +
      "?startDate=" +
      startDate +
      "&endDate=" +
      endDate;
    return axios.get(url).then(response => response.data.entity);
  };

  getTicketReservationsForUmpireByDateRange = (userId, startDate, endDate) => {
    const url = SERVICES_URL + "/umpires/events/requests/" + userId + "?startDate=" + startDate + "&endDate=" + endDate;
    return axios.get(url).then(response => response.data.entity);
  };

  getReservationsForGame = gamePk => {
    const url = SERVICES_URL + "/ticketRequest/" + gamePk + "/reservations";
    return axios.get(url).then(response => response.data.entity);
  };

  getTicketRequest = ticketRequestId => {
    const url = SERVICES_URL + "/ticketRequest/" + ticketRequestId;
    return axios.get(url).then(response => response.data.entity);
  };

  getTicketRequestDTO = ticketRequestId => {
    const url = SERVICES_URL + "/ticketRequest/" + ticketRequestId + "/withTickets";
    return axios.get(url).then(response => response.data.entity);
  };

  getReservation = reservationId => {
    const url = SERVICES_URL + "/ticketRequest/reservation/" + reservationId;
    return axios.get(url).then(response => response.data.entity);
  };

  deleteReservation = reservationId => {
    const url = SERVICES_URL + "/ticketRequest/" + reservationId;
    return axios.delete(url).then(response => response.data.entity);
  };

  deleteReservations = reservationIds => {
    const url = SERVICES_URL + "/ticketRequest/multi";
    return axios.put(url, { reservationIds: reservationIds }).then(response => response.data.entity);
  };

  getDisplayStyles = () => {
    const url = SERVICES_URL + "/displayStyles";
    return axios.get(url).then(response => response.data.entity);
  };

  getReportFieldsByType = reportType => {
    const url = SERVICES_URL + "/reportFields";
    return axios
      .get(url, {
        params: {
          reportType: reportType
        }
      })
      .then(response => response.data.entity);
  };

  getReportFieldsByOrgAndType = (orgId, reportType) => {
    const url = SERVICES_URL + "/orgReportFields";
    return axios
      .get(url, {
        params: {
          orgId: orgId,
          reportType: reportType
        }
      })
      .then(response => response.data.entity);
  };

  getReportFieldsByType = reportType => {
    const url = SERVICES_URL + "/reportFields";
    return axios
      .get(url, {
        params: {
          reportType: reportType
        }
      })
      .then(response => response.data.entity);
  };

  getReportFieldsByOrgAndType = (orgId, reportType) => {
    const url = SERVICES_URL + "/orgReportFields";
    return axios
      .get(url, {
        params: {
          orgId: orgId,
          reportType: reportType
        }
      })
      .then(response => response.data.entity);
  };

  updateOrgReportFields = (orgId, orgReportFields) => {
    const url = SERVICES_URL + "/orgReportFields/" + orgId;
    return axios.put(url, orgReportFields).then(response => response.data.entity);
  };

  saveSections = (sections, venueSeasonId) => {
    const createdOrUpdated = sections.filter(section => {
      return !(section.sectionId == null && section.isDeleted);
    });
    const url = SERVICES_URL + "/venues/" + venueSeasonId + "/sections";
    return axios.put(url, createdOrUpdated).then(response => response.data.entity);
  };

  getAllotmentAndRequests = (gamePK, userId) => {
    const url = SERVICES_URL + "/ticketRequest/" + gamePK + "/user/" + userId;
    return axios.get(url).then(response => response.data.entity);
  };

  getAllotmentAndRequestsMulti = (gamePks, userId) => {
    const url = SERVICES_URL + "/requests/allotments/multi/" + userId + "?gamePks=" + gamePks;
    return axios.get(url).then(response => response.data.entity);
  };

  addTicketRequestTransfer = ticketRequestTransfer => {
    const url = SERVICES_URL + "/ticketRequest/transfer";
    return axios.post(url, ticketRequestTransfer).then(response => response.data.entity);
  };

  getSectionInventoryByUserIdAndGamePk = (userId, gamePk) => {
    const url = SERVICES_URL + "/section/user/" + userId + "/gamePk/" + gamePk;
    return axios.get(url).then(response => response.data.entity);
  };
  getSectionInventoryByUserIdAndGamePks = (userId, gamePks) => {
    const url = SERVICES_URL + "/section/user/" + userId + "/multi?gamePks=" + gamePks;
    return axios.get(url).then(response => response.data.entity);
  };

  saveTicketRequest = ticketRequest => {
    const url = SERVICES_URL + "/ticketRequest";
    return axios.post(url, ticketRequest).then(response => response.data.entity);
  };

  saveTicketRequests = ticketRequests => {
    const url = SERVICES_URL + "/ticketRequest/multiRequests";
    return axios.post(url, { ticketRequests }).then(response => response.data.entity);
  };

  updateTicketRequest = ticketRequest => {
    const url = SERVICES_URL + "/ticketRequest";
    return axios.put(url, ticketRequest).then(response => response.data.entity);
  };

  canUpdateTicketRequest = ticketRequest => {
    const url = SERVICES_URL + "/ticketRequest/canUpdate?requestId=" + ticketRequest.ticketRequestId;
    return axios.get(url).then(response => response.data.entity);
  };

  getReservationsByOrgAndDate = (orgId, startDate, endDate) => {
    const url = SERVICES_URL + "/ticketRequest/org/" + orgId + "/game?startDate=" + startDate + "&endDate=" + endDate;
    return axios.get(url).then(response => response.data.entity);
  };

  getInventoryOverride = gamePk => {
    const url = SERVICES_URL + "/inventoryOverride/" + gamePk;
    return axios.get(url).then(response => response.data.entity);
  };

  updateInventoryOverride = (id, inventoryOverride) => {
    const url = SERVICES_URL + "/inventoryOverride/" + id;
    return axios.put(url, inventoryOverride).then(response => response.data.entity);
  };

  createInventoryOverride = inventoryOverride => {
    const url = SERVICES_URL + "/inventoryOverride";
    return axios.post(url, inventoryOverride).then(response => response.data.entity);
  };

  bulkCreateInventoryOverride = inventoryOverrides => {
    const url = SERVICES_URL + "/inventoryOverride/bulk";
    return axios.post(url, inventoryOverrides).then(response => response.data.entity);
  };

  deleteInventoryOverride = overrideId => {
    const url = SERVICES_URL + "/inventoryOverride/" + overrideId;
    return axios.delete(url).then(response => response.data.entity);
  };

  saveEventOverride = eventOverride => {
    const url = SERVICES_URL + "/eventOverride";
    return axios.post(url, eventOverride).then(response => response.data.entity);
  };

  saveEventBulkOverride = (eventOverride, depts) => {
    const url = SERVICES_URL + "/eventOverride/bulk?depts=" + depts;
    return axios.post(url, eventOverride).then(response => {
      if (response.data.alerts) {
        // this endpoint returns 207 Multi-Status
        // since errors are inside the payload
        // the top level alerts signifies to list out the errors for each override
        response.data.entity.forEach(o => {
          if (o.httpStatus === 500) {
            console.error(o.message);
          }
        });
      }
      return response.data.entity;
    });
  };

  updateEventOverride = eventOverride => {
    const url = SERVICES_URL + "/eventOverride";
    return axios.put(url, eventOverride).then(response => response.data.entity);
  };

  deleteEventOverride = eventOverrideId => {
    const url = SERVICES_URL + "/eventOverride/" + eventOverrideId;
    return axios.delete(url).then(response => response.data.entity);
  };

  getEventOverridesByGamePk = gamePk => {
    const url = SERVICES_URL + "/eventOverride/game/" + gamePk;
    return axios.get(url).then(response => response.data.entity);
  };

  deleteUser = userId => {
    const url = SERVICES_URL + "/users/" + userId;
    return axios.delete(url).then(response => response.data.entity);
  };

  getReservationSummaries = (orgId, homeAwayType, startDate, endDate, category) => {
    const url =
      SERVICES_URL +
      "/ticketRequest/" +
      orgId +
      "/summary?homeAwayType=" +
      homeAwayType +
      "&startDate=" +
      startDate +
      "&endDate=" +
      endDate +
      "&category=" +
      category;
    return axios.get(url).then(response => response.data.entity);
  };

  buildUserSummary = (orgId, searchTerm, homeAwayType, startDate, endDate, key, direction, category) => {
    const url =
      SERVICES_URL +
      "/report/user/summary?orgId=" +
      orgId +
      "&searchTerm=" +
      searchTerm +
      "&homeAwayType=" +
      homeAwayType +
      "&startDate=" +
      startDate +
      "&endDate=" +
      endDate +
      "&key=" +
      key +
      "&direction=" +
      direction +
      "&category=" +
      category;
    return axios({
      url: url,
      method: "GET",
      responseType: "blob" // important
    }).then(response => {
      return response;
    });
  };

  sendEventReport = (reportRequest, type) => {
    const url =
      SERVICES_URL +
      "/report/" +
      type +
      "?gamePk=" +
      reportRequest.gamePk +
      "&orgId=" +
      reportRequest.orgId +
      "&orgSettingsId=" +
      reportRequest.orgSettingsId +
      "&direction=" +
      reportRequest.direction +
      "&key=" +
      reportRequest.key +
      "&searchTerm=" +
      reportRequest.searchTerm +
      "&departmentTypes=" +
      reportRequest.departmentTypes +
      "&assignedFilter=" +
      reportRequest.assignedFilter;
    return axios({
      url: url,
      method: "GET",
      responseType: "blob" // important
    }).then(response => {
      return response;
    });
  };

  sendDigitalTicketInventory = reportRequest => {
    const url =
      SERVICES_URL +
      "/report/digitalTicketInventory" +
      "?gamePk=" +
      reportRequest.gamePk +
      "&orgId=" +
      reportRequest.orgId;
    return axios({
      url: url,
      method: "GET",
      responseType: "blob" // important
    }).then(response => {
      return response;
    });
  };

  sendUserReport = (reportRequest, type) => {
    const url =
      SERVICES_URL +
      "/report/" +
      type +
      "?userId=" +
      reportRequest.userId +
      "&orgId=" +
      reportRequest.orgId +
      "&startDate=" +
      reportRequest.startDate +
      "&endDate=" +
      reportRequest.endDate +
      "&direction=" +
      reportRequest.direction +
      "&category=" +
      reportRequest.category +
      "&searchTerm=" +
      reportRequest.searchTerm +
      "&key=" +
      reportRequest.key +
      "&requesterId=" +
      reportRequest.requesterId;
    return axios({
      url: url,
      method: "GET",
      responseType: "blob" // important
    }).then(response => {
      return response;
    });
  };

  getReportFilters = userId => {
    const url = SERVICES_URL + "/report/filter/" + userId;
    return axios.get(url).then(response => response.data.entity);
  };

  getHomeAwayBreakdown = (orgId, startDate, endDate) => {
    const url = SERVICES_URL + "/events/games/" + orgId + "/breakdown?startDate=" + startDate + "&endDate=" + endDate;
    return axios.get(url).then(response => response.data.entity);
  };

  getSeasons = () => {
    const url = SERVICES_URL + "/seasons";
    return axios.get(url).then(response => response.data.entity);
  };

  getCurrentSeasons = () => {
    const url = SERVICES_URL + "/seasons/current";
    return axios.get(url).then(response => response.data.entity);
  };

  getActiveSeasons = () => {
    const url = SERVICES_URL + "/seasons/active";
    return axios.get(url).then(response => response.data.entity);
  };

  getSeason = seasonId => {
    const url = SERVICES_URL + `/seasons/${seasonId}`;
    return axios.get(url).then(response => response.data.entity);
  };

  archiveSeason = seasonId => {
    const url = SERVICES_URL + `/seasons/archive/${seasonId}`;
    return axios.put(url).then(response => response.data.entity);
  };

  archiveSeasons = seasonIds => {
    const url = SERVICES_URL + `/seasons/archive`;
    return axios.put(url, seasonIds).then(response => response.data.entity);
  };

  createSeason = season => {
    const url = SERVICES_URL + "/seasons";
    return axios.post(url, season).then(response => response.data.entity);
  };

  updateSeason = season => {
    const url = SERVICES_URL + "/seasons";
    return axios.put(url, season).then(response => response.data.entity);
  };

  getSeasonTypes = () => {
    const url = SERVICES_URL + "/seasons/types";
    return axios.get(url).then(response => response.data.entity);
  };

  getNonGameEventForYearAndOrgIds = (year, orgIds) => {
    const url = SERVICES_URL + `/nge?orgs=${orgIds}&year=${year}`;
    return axios.get(url).then(response => response.data.entity);
  };

  getPostSeasonGamesForYearAndOrgIds = (year, orgIds) => {
    const url = SERVICES_URL + `/postseason?orgs=${orgIds}&year=${year}`;
    return axios.get(url).then(response => response.data.entity);
  };

  updateNonGameEventMappings = games => {
    const url = SERVICES_URL + "/nge";
    return axios.put(url, games).then(response => response.data.entity);
  };

  getAllOrgSettings = () => {
    const url = SERVICES_URL + "/orgs/settings";
    return axios.get(url).then(response => response.data.entity);
  };

  saveOrgSettings = orgSettings => {
    const url = SERVICES_URL + "/orgs/settings";
    return axios.post(url, orgSettings).then(response => response.data.entity);
  };

  getAvailableTickets = (statsId, gamePk, orgId, refresh = false, requestId = -1) => {
    return axios
      .get(
        `${SERVICES_URL}/ticketDelivery/getTickets?teamId=${statsId}&eventId=${gamePk}&comptixOrgId=${orgId}&refresh=${refresh}&requestId=${requestId}`
      )
      .then(response => response.data.entity);
  };

  getRefreshInformation = orgId => {
    return axios.get(`${SERVICES_URL}/ticketDelivery/${orgId}/lastRefresh`).then(response => response.data);
  };

  forwardTickets = (email, teamId, orgId, ticketIds, requestId, phoneNumber, countryCode, userInfo, gamePk) => {
    return axios
      .post(SERVICES_URL + "/ticketDelivery/forward", {
        email,
        teamId,
        orgId,
        ticketIds,
        requestId,
        phoneNumber,
        countryCode,
        userInfo,
        gamePk
      })
      .then(response => response.data.entity);
  };

  revokeTickets = (teamId, gamePk, orgId, forwardId, ticketIds, email, userInfo, requestId) => {
    return axios
      .post(SERVICES_URL + "/ticketDelivery/revoke", {
        teamId,
        gamePk,
        orgId,
        forwardId,
        ticketIds,
        email,
        userInfo,
        requestId
      })
      .then(response => response.data.entity);
  };

  getContactsByUserId = userId => {
    return axios.get(SERVICES_URL + `/contacts/user/${userId}`).then(response => response.data.entity);
  };

  saveContact = contact => {
    return axios.post(SERVICES_URL + `/contacts/saveContact`, contact).then(response => response.data.entity);
  };

  deleteContact = contact => {
    return axios.post(SERVICES_URL + `/contacts`, contact).then(response => response);
  };

  copyReservations = (reservationIds, gamePkToCopyTo) => {
    const copyRequest = {
      reservationIds: reservationIds,
      gamePkToCopyTo: gamePkToCopyTo
    };
    return axios.post(SERVICES_URL + "/ticketRequest/copy", copyRequest).then(response => response);
  };

  getEventMapping = (statsId, gamePk) => {
    return axios.get(SERVICES_URL + `/ticketDelivery/org/${statsId}/game/${gamePk}`).then(response => response);
  };

  /************* Test API Functions **************/

  alertTest = () => {
    const url = SERVICES_URL + "/alerts/all";
    return axios.get(url, { responseType: "arraybuffer" }).then(response => response.data.entity);
  };

  errorAlertTest = () => {
    const url = SERVICES_URL + "/alerts/errors";
    return axios.get(url).then(response => response.data.entity);
  };
}

export default CompTixApi;
